import React from "react"
import { Link } from "gatsby"
import Layout from "../components/shared/Layout"
import SEO from "../components/seo"

const IndexPage = () => (<Layout>

<SEO title="Forside"/>
<div className="wrap" role="document">
        <div className="content">

            <main className="main" id="main">
                <header className="header">
                    <div className="container">
                        <div className="header__caption">
                            <h1>Digital profilmanual</h1>
                            <p className="header__lead">Versjon 1.0</p>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-10 mx-auto ingress">
                            <p className="ingress lead">
                                Saga KL er en kjede av selveide regnskapskontorer som tilrettelegger for felles verdiskaping
                                med lokal tilpasning. Felles markedsføring og merkevarebygging er et viktig verktøy for
                                å tiltrekke oss kunder, samarbeidspartnere og nye medlemmer. </p>

                                <p className="lead">
                                Hos Saga KL utnytter vi
                                stordriftsfordelene hva gjelder felles grafisk profil, nettsted, markedsføring og salgsverktøy. Samtidig
                                som vi tilrettelegger for at medlemmene har selvstendig juridisk navn, er synlig i egne lokale
                                medier og aktive med lokale aktiviteter og sponsorater.
                                </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/logos">
                                <div className="card">
                                    <img src="img/sections/index/logo.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL logoer"/>
                                    <div className="card__inner">
                                        <h2>Logoer</h2>
                                        <p>Logoen identifiserer Saga KL i markedet. På en rask, visuelt symbolsk måte presenterer logoen oss for omverdenen.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/colors">
                                <div className="card">
                                    <img src="img/sections/index/farger.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL profilfarger"/>
                                    <div className="card__inner">
                                        <h2>Farger</h2> 
                                        <p>Riktig bruk av farger er viktig for å få Saga KL til å skape en identitet i markedet.</p> 
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/typography">
                                <div className="card">
                                    <img src="img/sections/index/typografi.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL typografi"/>
                                    <div className="card__inner">
                                        <h2>Typografi</h2> 
                                        <p>Typografien er en viktig del av vår identitet. Selskapets skrifttyper er nødvendig for å få det rette utseendet på alt materiell.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/images">
                                <div className="card">
                                    <img src="img/sections/index/bilder.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL bildestil"/>
                                    <div className="card__inner">
                                        <h2>Bildestil</h2>
                                        <p>Saga KL bildestil forsterker selskapets sjel og visuelle uttrykk. Et utvalg bilder er tilgjengelig i bildebanken.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/graphic-elements">
                                <div className="card">
                                    <img src="img/sections/index/grafisk-elementer.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL grafiske elementer"/>
                                    <div className="card__inner">
                                        <h2>Grafiske elementer</h2>
                                        <p>Tre forskjellige elementer kan lastes ned. Disse kan brukes for å dekorere, skape variasjon og å forsterke den grafiske identiteten.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6 col-lg-4 card-wrapper">
                            <Link className="card-link" to="/stationary">
                                <div className="card">
                                    <img src="img/sections/index/aksidens.png" className="mx-auto d-block" width="140" height="140" alt="Saga KL aksidens og presentasjoner"/>
                                    <div className="card__inner">
                                        <h2>Aksidens og trykksaker</h2>
                                        <p>Maler til bruk for kontortrykksaker som konvolutter, brevark og visittkort, samt MS Word og Powerpoint</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div> 
                <div className="container">
                    <div className="row">
                        <div className="col-10 mx-auto ingress">
                                <p className="lead">
                                For å bygge en sterk merkevare og for å bygge enhetlig profil, er det viktig å “snakke med den
                                samme stemmen og bære de samme klærne”. Formålet med retningslinjene i denne profilhåndboken,
                                er å gjøre det mulig for Saga KL-ansatte, leverandører, partnere og eksterne designere
                                å følge bedriftens merkevarestrategi. Her finner dere logoer til alle medlemsbedriftene, og
                                alle kan laste ned ferdige maler og deretter få sine lokale samarbeidspartnere (trykkerier, medier
                                etc.) til å legge inn deres unike informasjon.
                                </p>
                                <p className="lead">
                                Det visuelle uttrykket skal underbygge Saga KL sin visjon om å være en fremoverlent samarbeidspartner.
                                Saga KL ønsker å bli oppfattet som en verdiskaper som bidrar til effektive prosesser
                                og besparende tiltak. Den grafiske profilen må således utstråle varme, samtidig som en er
                                pålitelig og nøyaktig.</p>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    </div>


</Layout>)

export default IndexPage
